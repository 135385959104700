import React from 'react';
import PropTypes from 'prop-types';
import NewsArticles from '../News/ArticleFeed';
import withQuery from '../../lib/withQuery';

const News = ({
  data,
  variables,
  articlesField,
  tagVariables,
  fetchMore,
  loading,
  preloadFirst,
}) => {
  const articles = data.tag
    ? data.tag[articlesField]
    : data[articlesField];

  return (
    <NewsArticles
      tagVariables={tagVariables}
      fetchMore={fetchMore}
      articles={articles}
      data={data}
      variables={variables}
      field={data.tag ? 'tag' : articlesField}
      articlesField={data.tag ? articlesField : null}
      loading={loading}
      preloadFirst={preloadFirst}
    />
  );
};

News.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.shape({}),
  }),
  variables: PropTypes.shape({}).isRequired,
  tagVariables: PropTypes.shape({}),
  fetchMore: PropTypes.func,
  articlesField: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  preloadFirst: PropTypes.bool,
};

News.defaultProps = {
  tagVariables: undefined,
  data: {},
  fetchMore: undefined,
  articlesField: null,
  preloadFirst: false,
};

export default withQuery(News);
